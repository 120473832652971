<template>
  <vuestic-modal
    v-bind:large="true"
    ref="modal"
    cancelClass="none"
    okClass="none"
    class="style-p-0"
  >
    <div slot="title">Novo Ação</div>

    <acao
      :acaoDefaut="acao"
      v-on:exit="fecharModal()"
      v-on:success="success()"
      class="no-shadow"
      ref="acao"
    />
  </vuestic-modal>
</template>

<script>
import acao from '@/components/my-components/form/acao.vue';

export default {
  components: { acao },
  props: {
    acao: {
      type: Object,
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
      this.$refs.acao.adicionarAcaoPorProps();
    },
    fecharModal() {
      this.$refs.modal.close();
    },
    success() {
      this.$emit('success');
      this.fecharModal();
    },
  },
};

</script>
<style>
.vuestic-modal .style-p-0 .modal-container .modal .modal-dialog .modal-body {
  padding: 0;
}
.no-shadow .var-row .flex .vuestic-widget {
  box-shadow: none;
}
</style>
